import React, {useState} from "react"
import Layout from "../components/layout";
import globalStyle from "../styles/global.module.css";
import becomeAMemberStyle from "./become-a-member.module.css";
import {Col, Row, Container, Form, Alert, Button} from "react-bootstrap";
import {graphql} from "gatsby";
import unimomStyle from "./unimom-minuet.module.css";
import Img from "gatsby-image";
import formStyle from "./form.module.css";
import ContactEmailAddress from "../components/contactEmailAddress";
import styles from "./petition.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import env from "../config/env";

const UnimomMinuetPage = ({data}) => {

  const [formState, setFormValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    phone: ""
  });

  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const [formResult, setFormResult] = useState({});
  const submitUrl = 'https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=204bf0af0e';
  const [mailchimpAddMemberShowError, setMailchimpAddMemberShowErrorAlert] = useState({show: false, errorMessage: ""});


  const resetFormAfterSubmit = () => {
    setFormValues({
      email: "",
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: ""
    });
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = ["firstName", "lastName", "addressLine1", "city", "state", "zipCode", "phone", "email"];
    for (const field in formState) {
      if(requiredFields.indexOf(field) !== -1) {
        if(!formState[field]) {
          setShowErrorAlert({
            show: true,
            onField: field.charAt(0).toUpperCase()+ field.slice(1)
          });

          return;
        }
      }
    }

    const result = await addToMailchimp(formState.email, {
      FNAME: formState.firstName,
      LNAME: formState.lastName,
      "ADDRESS[addr1]": formState.addressLine1,
      "ADDRESS[addr2]": formState.addressLine2,
      "ADDRESS[city]": formState.city,
      "ADDRESS[state]": formState.state,
      "ADDRESS[zip]": formState.zipCode,
      PHONE: formState.phone
    }, submitUrl);

    setFormResult(result);

    if (result.result === 'success') {
      setShowAlert(true);
      resetFormAfterSubmit();
    } else {
      const alreadyAMemberError = formState.email + ` is already subscribed to list`;
      if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
        setMailchimpAddMemberShowErrorAlert({
          show: true,
          msg: 'You already subscribed to list!'
        });
      } else {
        setFormResult(result);
        setMailchimpAddMemberShowErrorAlert({
          show: true,
          errorMessage: result.msg
        })
      }
    }
  };

  const handleFormChange = (e) => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Layout className={becomeAMemberStyle.becomeAMemberContent}
            structuredData={{
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "Moms on Maternity’s Breast Pump of Choice is the Unimom Minuet",
              "image": env.domain + data.unimomMinuet.publicURL,
              "brand": {
                "name": "Unimom Minuet"
              },
              "description": "We talked to the experts and the consensus is Unimom Minuet Pump has the best quality pumps on the market!"
            }}>
      <Container>
        <Row>
          <Col className={unimomStyle.unimomContent} >
            <h1 className={globalStyle.entryTitle}>
              <a className={unimomStyle.titleLink} href="https://unimomus.com/products/minuet" target="_blank" rel="noopener noreferrer">
                Moms on Maternity’s Breast Pump of Choice is the Unimom Minuet!
              </a>
            </h1>

            <Row className={unimomStyle.subtitle + "justify-content-center"}>
              <Col md={10} className={unimomStyle.contentCenter}>
                <h4>
                  We talked to the experts and the consensus is
                  <p className={unimomStyle.boldStyle}> Unimom has the best quality pumps on the market!</p>
                </h4>
              </Col>
            </Row>

            <Row className={unimomStyle.promoBannerContainer}>
              <Col>
                <Row>
                  <Col>
                    <Img fluid={data.unimomBanner.childImageSharp.fluid} />
                  </Col>
                </Row>
                <Row className={unimomStyle.promoBannerSmallDevices}>
                  <Col>
                    <h2 className={unimomStyle.promoTitleSmallDevices}>Feeling Lucky?</h2>
                    <i className="fas fa-angle-double-down"></i>
                  </Col>
                </Row>
                <Row className={unimomStyle.promoBanner}>
                  <Col>
                    <h2 className={unimomStyle.promoTitle}>Feeling Lucky?</h2>
                    <p className={unimomStyle.promoSubtitle}>
                      On the 1st and the 15th of each month Moms on Maternity and Unimom are gifting a new or
                       expecting Mama a Unimom Minuet Pump.
                    </p>
                    <Row className={unimomStyle.promoButtonContent}>
                      <Col>
                        <Button className={unimomStyle.promoButton}  href="#registerFormId" variant="outline">ENTER TO WIN!</Button>
                      </Col>
                    </Row>
                    <p className={unimomStyle.promoChoice}>OR</p>
                    <p className={unimomStyle.promoDiscountContent}>
                      Use
                      <span className={unimomStyle.promoCode}>  MOMSONMATERNITY  </span>
                      code to
                      <a href="https://unimomus.com/" target="_blank" rel="noopener noreferrer">
                        <Button className={unimomStyle.promoButton} variant="outline"> GET ONE NOW!</Button>
                      </a>
                    </p>
                    <p> from their website with
                      <span className={unimomStyle.promoCode}>  15% DISCOUNT!  </span>
                    </p>
                  </Col>
                </Row>

                <Row className={unimomStyle.promoContentSmallDevices}>
                  <Col>
                    <Row>
                      <Col>
                        <Img fluid={data.unimomBannerSmallDevices.childImageSharp.fluid} />
                        <Img fluid={data.unimomBannerSmallDevices.childImageSharp.fluid} />
                      </Col>
                    </Row>

                    <Row className={unimomStyle.promoTextSmallDevices}>
                      <Col>
                        <p className={unimomStyle.promoSubtitle}>
                          On the 1st and the 15th of each month Moms on Maternity and Unimom are gifting a new or
                          expecting Mama a Unimom Minuet Pump.
                        </p>
                        <div className={unimomStyle.promoButtonContent}>
                          <Button className={unimomStyle.promoButton}  href="#registerFormId" variant="outline">REGISTER NOW!</Button>
                        </div>
                        <p className={unimomStyle.promoChoice}>OR</p>
                        <p className={unimomStyle.promoDiscountContent}>
                          Use
                          <span className={unimomStyle.promoCode}>  MOMSONMATERNITY  </span>
                          code to
                          <a href="https://unimomus.com/products/minuet" target="_blank" rel="noopener noreferrer">
                            <Button className={unimomStyle.promoButton} variant="outline"> GET ONE NOW!</Button>
                          </a>
                        </p>
                        <p className={unimomStyle.discountText}> from their website with
                          <span className={unimomStyle.promoCode}>  10% DISCOUNT!  </span>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  The <a className={unimomStyle.linkStyle} href="https://unimomus.com/products/minuet" target="_blank" rel="noopener noreferrer">Minuet Breastpump</a> is a small, rechargeable, hi-performance pump, with a two year warranty and amazing specifications.
                </p>
                <h3 className={unimomStyle.registerMessage}>Enter to win here!</h3>
              </Col>
            </Row>

            <Form className={unimomStyle.formContent} id="registerFormId" name="registerFormId" onSubmit={_handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId="firstName">
                  <Form.Label className={[formStyle.formLabel, formStyle.required]}>First Name</Form.Label>
                  <Form.Control size="sm" value={formState.firstName} name="firstName" type="text" placeholder="First name" onChange={handleFormChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="lastName">
                  <Form.Label className={[formStyle.formLabel, formStyle.required]}>Last Name</Form.Label>
                  <Form.Control size="sm" value={formState.lastName} name="lastName" type="text" placeholder="Last name" onChange={handleFormChange} />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formAddress1">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Address</Form.Label>
                <Form.Control size="sm" value={formState.addressLine1} type="text" name="addressLine1" placeholder="Address Line 1" onChange={handleFormChange} />
              </Form.Group>

              <Form.Group controlId="formAddress2">
                <Form.Control size="sm" value={formState.addressLine2} type="text" name="addressLine2" placeholder="Address Line 2" onChange={handleFormChange} />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} controlId="formCity">
                  <Form.Control size="sm" value={formState.city} name="city" type="text" placeholder="City" onChange={handleFormChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="formState">
                  <Form.Control className={[styles.formControl, styles.stateSelect]} as="select" name="state" onChange={handleFormChange}>
                    <option value="">Select State</option>
                    {data.site.siteMetadata.usaStates.map(state => (
                      <option value={state.name}>{state.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formZipCode">
                <Form.Control size="sm" value={formState.zipCode} type="text" name="zipCode" placeholder="Zip Code" onChange={handleFormChange} />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email</Form.Label>
                <Form.Control size="sm" value={formState.email} type="email" name="email" placeholder="Your email adress" onChange={handleFormChange} />
              </Form.Group>

              <Form.Group controlId="formPhone">
                <Form.Label className={[formStyle.formLabel, formStyle.required]}>Phone</Form.Label>
                <Form.Control size="sm" value={formState.phone} type="number" name="phone" placeholder="Your phone number" onChange={handleFormChange} />
              </Form.Group>

              <Button type="submit" className={[formStyle.btnPrimary, formStyle.submitButtonPink]}>
                Submit
              </Button>
            </Form>

            {
              mailchimpAddMemberShowError.show && formResult.result === 'error' &&
              <Alert variant="danger" onClose={() => setMailchimpAddMemberShowErrorAlert(false)} dismissible>{mailchimpAddMemberShowError.msg}</Alert>
            }

            {
              showAlert && formResult.result === 'error' &&
              <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible
              >Some error has occurred. Please send an email to <ContactEmailAddress/></Alert>
            }
            {
              showAlert && formResult.result === 'success' &&
              <Alert variant="primary" onClose={() => setShowAlert(false)} dismissible
              >Thank you for subscribing!</Alert>
            }
            {
              showError.show &&
              <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible
              >{showError.onField} field should not be empty</Alert>
            }

            <Row>
              <Col>
                <p>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  Winners will be emailed and text messaged on the 1st of 15th of every month.
                  Winners will also be announced on Moms on Maternity’s social media every 1st and 15th.
                  You can enter once per month.</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  <span className={globalStyle.required}></span>
                  On Unimom website you get 15% off entire order (no minimum purchase required) with MOMSONMATERNITY code, active until May 31 2020</p>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    unimomMinuet: file(relativePath: { eq: "market/unimom-minuet/minuet-clear.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unimomBanner: file(relativePath: { eq: "market/unimom-minuet/unimom-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    unimomBannerSmallDevices: file(relativePath: { eq: "market/unimom-minuet/unimom-banner-small-devices.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        usaStates {
          name
          abbreviation
        }    
      }
    }
  }
`;




export default UnimomMinuetPage


